<template>
  <div class="page-wrapper">
    <!-- <div class="page-name">Page Not Found</div> -->
    <div class="not-found-img">
      <img src="/img/not-found.jpg" alt="" />
    </div>
    <div class="btn-wrapper">
      <button class="grupa-blue-btn" @click="handleGoToHomePage()">
        Back to Home Page
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},

  data: () => ({}),

  created() {},
  methods: {
    handleGoToHomePage() {
      this.$router.push({ name: "sign_up" });
    },
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  flex-direction: column;
}
.page-name {
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
  text-align: left;
  margin-top: 24px;
}
.not-found-img img {
  width: 400px;
  height: 500px;
}
.btn-wrapper {
  margin-top: 24px;
}
</style>
