var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrapper" }, [
    _vm._m(0),
    _c("div", { staticClass: "btn-wrapper" }, [
      _c(
        "button",
        {
          staticClass: "grupa-blue-btn",
          on: {
            click: function ($event) {
              return _vm.handleGoToHomePage()
            },
          },
        },
        [_vm._v("\n      Back to Home Page\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "not-found-img" }, [
      _c("img", { attrs: { src: "/img/not-found.jpg", alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }